body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

* {
  box-sizing: border-box;
}
.appBar{
  height: 50px;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  color: white;
}

.textCenter{
  text-align: center;
  width: 100%;
}

.canvas{
  max-height: calc(100vh - 75px);
  max-width: 100vw;
  height: calc(100vh - 75px);
  width: 100%;
  display: block;
}

.outerContainer{
  background-color: #121212;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
}

h1{
  font-weight: bold;
  margin-left: 15px;
  font-size: 18px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.hideElement{
  display: none
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.tableHeader{
  font-weight: bold;
  cursor: pointer;
} 

.tableTodos{
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  row-gap: 15px;
  column-gap: 15px;
}

.priorities{
  font-size: 25px;
  cursor: pointer;
}
.priorityEnabled{
  color: #287A78;
}
.priorityDisabled{
  color: #bddcdb;
}

.todoCompleted{
  text-decoration: line-through;
}

.gridVCenter{
  align-self: center;
}

input, button{
  height: 35px;
  border-color: #3AAFA9;
  border-radius: 0%;
  border-style: solid;
}
input:focus {
  outline: none;
  border:2px solid #287A78;
}

button{
  background-color: #3AAFA9;
  color: white;
}
button:hover{
  background-color: #287A78;
  border-color: #287A78;
}

.fitContent{
  display: inline
}

.text{
  color: white;
}

.column{
  display: flex;
  flex-direction: column;
  gap: 20px
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}